/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components), {HrefLangManager, VideoIframe, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!VideoIframe) _missingMdxReference("VideoIframe", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/resources/hearing-loss/tinnitus-causes-symptoms-possible-solutions/'], ['en', 'https://www.hear.com/resources/hearing-loss/tinnitus-causes-symptoms-possible-solutions/'], ['en-US', 'https://www.hear.com/resources/hearing-loss/tinnitus-causes-symptoms-possible-solutions/'], ['en-CA', 'https://ca.hear.com/resources/hearing-loss/tinnitus-causes-symptoms-possible-solutions/']]
  }), "\n", React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "tinnitus-causes-symptoms-and-possible-solutions",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tinnitus-causes-symptoms-and-possible-solutions",
    "aria-label": "tinnitus causes symptoms and possible solutions permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tinnitus: Causes, symptoms, and possible solutions"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Tinnitus (pronounced tih-NIGHT-us or TIN-ih-tus) is a condition that affects approximately 15% of the world’s population. Although there is no cure or over-the-counter remedy for ringing in the ears, there are several things you can try to ease the symptoms. In this article, we’ll provide you with more information on tinnitus and explore some treatment options."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Here’s a great video from the Mayo Clinic that only takes a minute to watch."), "\n", React.createElement(VideoIframe, {
    title: "Is tinnitus causing that ringing in your ear?",
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/IlGVzRBBQFM?feature=oembed"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-tinnitus",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-tinnitus",
    "aria-label": "what is tinnitus permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is tinnitus?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To be honest, researchers are not 100% sure what tinnitus is. But what they do know is that it has a high correlation with hearing loss. It’s typically described as a ringing, buzzing, or hissing in the ears. Approximately 50 million Americans experience some form of tinnitus, so it’s safe to say you’re in good company if your ears are ringing as you read this article. Ten to twenty percent will experience chronic tinnitus (a duration over three months). Symptoms most commonly occur in people over the age of 40. However, people of all ages are susceptible to some form of disruptive and persistent ear ringing."), "\n", React.createElement(LandingPageCta, {
    copy: "Speak with an expert",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms-of-tinnitus-whats-that-sound-in-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms-of-tinnitus-whats-that-sound-in-your-ears",
    "aria-label": "symptoms of tinnitus whats that sound in your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms of tinnitus: What’s that sound in your ears?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Symptoms can vary from person to person. According to an online poll, these are some of the words people use to describe what they hear. These sounds can either be constant or intermittent. For some, tinnitus symptoms can be so loud that it interferes with everyday activities. In mild cases, the ringing may feel like a minor annoyance that is more noticeable in quiet settings."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "the-consequences-of-severe-tinnitus",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-consequences-of-severe-tinnitus",
    "aria-label": "the consequences of severe tinnitus permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The consequences of severe tinnitus"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The brain is used to hearing a certain amount of sound. Hearing loss reduces the amount of sound that gets to the brain. As a result, it has to turn up its internal amplifier, so to speak, in an attempt to hear clearly. However, this “turning up” in the brain is perceived as a ringing or buzzing in the ears. In other words, the brain has to create sound to fill in the empty space caused by loss of hearing. Consequently, this can take a huge toll on your mental and emotional well-being as your brain has to work harder to hear and understand. In fact, studies show that approximately 35% of tinnitus patients have anxiety, 13-28% have depression, and 25% exhibit psychiatric disorder."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-of-tinnitus-how-did-the-ringing-get-in-your-ears",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-of-tinnitus-how-did-the-ringing-get-in-your-ears",
    "aria-label": "causes of tinnitus how did the ringing get in your ears permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes of tinnitus: How did the ringing get in your ears?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The consensus among hearing care professionals is that tinnitus stems from damage to the outer hair cells of the cochlea (the portion of the inner ear that looks like a snail shell). So, the question is, how did these hair cells get damaged? There are numerous reasons, but the two most basic ones are trauma and long-term degradation."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "trauma",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#trauma",
    "aria-label": "trauma permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Trauma"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Trauma can be a single external event like an injury or constant exposure to loud noises. Some common culprits include gunfire, explosions, and crashes. People who work around loud machinery have a higher risk of developing tinnitus."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "long-term-degradation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#long-term-degradation",
    "aria-label": "long term degradation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Long-term degradation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Similar to trauma, long-term degradation can occur from exposure to noises. The difference, however, is that the noises are less severe and over the course of a lifetime. For instance, going to lots of loud concerts without earplugs can slowly chip away at your ability to hear. In addition, it’s not unusual to develop age-related hearing loss as you get older. And as we’ve already learned, numerous studies link tinnitus to hearing loss. That being said, if you’re not in your 60s yet and don’t wear proper ear protection, you run the risk of developing hearing loss — and tinnitus — sooner than later."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "try-hearing-aids-with-tinnitus-maskers-for-45-days",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#try-hearing-aids-with-tinnitus-maskers-for-45-days",
    "aria-label": "try hearing aids with tinnitus maskers for 45 days permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Try hearing aids with tinnitus maskers for 45 days"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, you have access to the largest selection of name-brand hearing aids that are equipped with tinnitus maskers. All you have to do is sign up for our 45-day trial and one of our hearing consultants will discuss all your available options. We will provide you with free, unbiased information so that you can be a better-informed consumer. This is why we are one of the most reputable companies in the hearing care industry. If you haven’t signed up for a 45-day trial, just click the button below to begin your journey to better hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-treat-tinnitus",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-treat-tinnitus",
    "aria-label": "how to treat tinnitus permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to treat tinnitus:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Here are three ways you can make tinnitus easier to manage:"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "wearing-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wearing-hearing-aids",
    "aria-label": "wearing hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wearing hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have hearing loss with tinnitus, the best way to treat it is by wearing hearing aids. Hearing aids work by reintroducing those sounds you’re having trouble hearing back into your brain. And as the sounds return, your brain shuts off the internal amplifier that is causing the ringing. Approximately 60% of the people who have tinnitus and wear hearing aids experience significant relief. Many of today’s hearing aids also come with tinnitus maskers built into them to offer additional relief if needed. A hearing care professional can use this feature to program the devices to a comfortable level, allowing you to hear clearly — and without the ringing."), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for a 45-day trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "masking",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#masking",
    "aria-label": "masking permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Masking"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This method is essentially listening to sounds that distract you from the ringing or buzzing produced by tinnitus. For instance, if tinnitus is keeping you from getting a good night’s rest, you can put a fan in your room and the sound it makes will help mask the ringing. There are also white noise generators you could plug into a wall and adjust the sounds to your liking. Lastly, there are a variety of smartphone apps that allow you to play different sounds, adjust the volume, and change the pitch until you find that sweet spot that masks tinnitus."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "stress-relief",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#stress-relief",
    "aria-label": "stress relief permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Stress relief"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A lot of research has been conducted on the topic of tinnitus, and one of the key findings is that stress can trigger tinnitus. When you’re stressed out, there’s a tendency to perceive the ringing much louder than it actually is. So, what are you supposed to do when tinnitus flares up? Lower your stress levels. But we all know that’s easier said than done. The best thing you can do is try to do activities that help relieve stress, such as going for a walk, getting a massage, or doing deep breathing exercises. When tinnitus gives you an unsolicited ring, just remember this line: A stress-free day keeps your tinnitus away."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-mindear-can-help-you-in-your-tinnitus-journey",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-mindear-can-help-you-in-your-tinnitus-journey",
    "aria-label": "how mindear can help you in your tinnitus journey permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How MindEar can help you in your tinnitus journey:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "MindEar offers a range of scientifically-backed solutions that can help you manage your tinnitus symptoms effectively, as well as educational content to help you learn facts about tinnitus. From sound therapy to cognitive behavioral therapy or mindfulness techniques, our platform aims to provide you with the tools to take control of your condition so that you can live a life without noticing it. MindEar is here to guide you on that journey."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Download the MindEar App today: ", React.createElement("a", {
    href: "https://mindear.app.link/hear",
    target: "_blank"
  }, "https://mindear.app.link/hear")), "\n", React.createElement(LandingPageCta, {
    copy: "Sign up for a 45-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
